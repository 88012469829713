import styled, { css, keyframes } from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

import {
  StyledMarqueeWrapperProps,
  StyledMarqueeBackgroundProps
} from "./types";

export const scroll = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
`;

export const Wrapper = styled.div<StyledMarqueeWrapperProps>`
  width: 100vw;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);

  ${({ $isIndent }) =>
    $isIndent &&
    css`
      top: 12rem;
    `}

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `};

  ${({ $isActiveOnMobile }) =>
    !$isActiveOnMobile &&
    css`
      display: none;

      @media ${media.mobile} {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    `}
`;

export const Text = styled.span`
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-size: 5.875rem;
  line-height: 1.1;
  color: inherit;

  @media ${media.tablet} {
    font-size: 7.5rem;
  }
`;

export const MarqueeContent = styled.ul`
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;

  @media (prefers-reduced-motion: reduce) {
    animation-play-state: paused !important;
  }
`;

export const Marquee = styled.div<StyledMarqueeBackgroundProps>`
  --gap: 4rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  width: 125%;
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `};
  ${({ $index }) => css`
    &:nth-child(${$index + 1}) {
      margin-left: calc(-25% * ${$index});
      width: calc(100% + (25% * ${$index}));
    }
  `}
  &:nth-child(even) ${MarqueeContent} {
    animation-direction: reverse;
  }
  ${({ $withAnimation }) =>
    $withAnimation &&
    css`
      ${MarqueeContent} {
        animation: ${scroll} 75s linear infinite;
      }
    `}
`;
