import React from "react";
import { storyblokEditable } from "@storyblok/react";

import MarqueeBackground from "@components/molecules/MarqueeBackground";

import { MarqueeBackgroundStoryblokProps } from "./types";

const MarqueeBackgroundStoryblok: React.FC<
  MarqueeBackgroundStoryblokProps
> = ({ blok }) => {
  const {
    rowsNumber,
    backgroundText,
    withBackgroundAnimation,
    isActiveOnMobile,
    color,
    backgroundColor
  } = blok;

  const params = {
    text: backgroundText || "Text",
    rowsNumber: rowsNumber ? +rowsNumber : 1,
    $withAnimation: withBackgroundAnimation,
    isActiveOnMobile,
    $color: color?.color,
    $backgroundColor: backgroundColor?.color
  };

  return (
    <MarqueeBackground
      {...{ ...params, ...storyblokEditable(blok) }}
    />
  );
};

export default MarqueeBackgroundStoryblok;
