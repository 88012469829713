import React from "react";

import { MarqueeBackgroundProps } from "./types";
import * as Styled from "./styles";

const MarqueeBackground: React.FC<MarqueeBackgroundProps> = ({
  text,
  rowsNumber,
  $withAnimation,
  $isActiveOnMobile,
  $color,
  $backgroundColor,
  $isIndent = true
}) => {
  const rows = Array(rowsNumber).fill(text);

  return (
    <Styled.Wrapper
      {...{ $isActiveOnMobile, $backgroundColor, $isIndent }}
    >
      {rows.map((item, $index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={`${item}-${$index}`}>
          <Styled.Marquee {...{ $index, $withAnimation, $color }}>
            <Styled.MarqueeContent>
              <li>
                <Styled.Text>{item}</Styled.Text>
              </li>
              <li>
                <Styled.Text>{item}</Styled.Text>
              </li>
              <li>
                <Styled.Text>{item}</Styled.Text>
              </li>
            </Styled.MarqueeContent>
            <Styled.MarqueeContent aria-hidden="true">
              <li>
                <Styled.Text>{item}</Styled.Text>
              </li>
              <li>
                <Styled.Text>{item}</Styled.Text>
              </li>
              <li>
                <Styled.Text>{item}</Styled.Text>
              </li>
            </Styled.MarqueeContent>
          </Styled.Marquee>
        </React.Fragment>
      ))}
    </Styled.Wrapper>
  );
};

export default MarqueeBackground;
